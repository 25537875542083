import { React } from "react";

function DeclaracionDePrivacidad({ isDay }) {

    return (
        <div className={isDay === "dia" ? "container col-xxl-8 px-4 py-5" : "container col-xxl-8 px-4 py-5 text-white"}>
            <div className="text-start">
                <h2 className="text-center my-4">Política de Privacidad de Amática</h2>
                <p>
                    La presente Política de Privacidad tiene por finalidad informar a los usuarios del tratamiento de los datos personales
                    que se recolectan a través del presente sitio web <a _blank href="amatica.cl">amatica.cl</a>, en cumplimiento de la normativa
                    de protección de datos personales que está regulada en distintos cuerpos legales. En efecto, su marco normativo se
                    encuentra conformado, entre otras, por la <a _blank href="https://www.bcn.cl/leychile/navegar?idNorma=141599">Ley N° 19.628</a>,
                    de 1999, sobre Protección de la Vida Privada; lo consagrado en el artículo 19 Nº4 de la Constitución Política de la República;
                    la <a _blank href="https://www.bcn.cl/leychile/navegar?idNorma=141599">Ley N° 20.285</a>, sobre Acceso a la Información
                    Pública; y la <a _blank href="https://www.bcn.cl/leychile/navegar?idNorma=1037366&idParte=9237711&idVersion=2012-02-17">Ley N° 20.575</a>,
                    que Establece el Principio de Finalidad en el Tratamiento de Datos Personales.
                </p>

                <h4>Qué información personal recopilamos</h4>

                <p>
                    Al utilizar nuestros servicios, recopilamos información limitada y necesaria para mejorar tu experiencia únicamente en lo
                    correspondiente a la comunicación con nosotros mediante los formularios de contacto, para ofrecerte soluciones personalizadas.
                </p>
                <p>
                    Otros datos recopilados mediante los formularios de contacto y el acceso al sitio web, pueden incluirse de tipos demográficos,
                    preferencias y detalles de interacción, los cuales son recopilados de forma automática por los servidores. Toda la
                    información recopilada está protegida y no se comparte con terceros a menos que esto beneficie al usuario y sea consentido
                    explícitamente por el. Estamos comprometidos a proteger tus datos y ofrecerte una experiencia segura con Amática.
                </p>


                <h4>Formas de recopilación de datos</h4>
                <p>
                    <span className="fw-bold">Formulario de contacto:</span> Este formulario tiene como objetivo crear una solicitu de contacto
                    con un encargado en Amática que pueda ayudarle a resolver susu consultas. Los datos recopilados son única y exclusivamente
                    los ingresados manualmente por el usuario solicitante.
                </p>
                <p>
                    <span className="fw-bold">Automáticos:</span> Los servidores del sitio web se encargan de registrar datos como la dirección
                    IP de la persona en conexión, dispositivo, navegador web, y cantidad de información cargada y utilizada en el servidor.
                </p>

                <h4>Uso de la información</h4>
                <p>
                    Los datos personales de los usuarios serán utilizados únicamente para el cumplimiento de los fines indicados en el (los)
                    formulario (s) correspondiente (s) y siempre dentro de las competencias y atribuciones del Servicio Nacional del Consumidor.
                    El tratamiento de datos personales sólo podrá considerarse fundado en el cumplimiento de las facultades legales del servicio y
                    la ejecución de medidas que sean establecidas el ejercicio de la protección de los consumidores, cuyo propósito busque dotarlos
                    de mayores herramientas para el ejercicio de sus derechos.
                </p>
                <p>
                    Bajo ningún concepto Amática utilizará los datos recolectados para generar ingresos de cualquier tipo ya que son almacenados
                    única y exclusivamente de forma privada.
                </p>

                <h4>Retención de datos</h4>
                <p>
                    Cuando envíes datos de contacto a través de los formularios destinados a ellos, mantendremos tus datos para registrarte de forma
                    interna como potencial cliente y esa información se almacenará hasta que Amática deje de brindar servicios.
                </p>
                <p>
                    Si usted desea ser excluido después de haber sido ingresado puede solicitarnos la eliminar de esta información. y si solo desea
                    consultar la información que mantenemos sobre usted puede solicitarla en cualquier momento mediante el formulario destinado a ello.
                </p>

                <h4>Derechos de los usuarios</h4>
                <p>
                    El usuario podrá en todo momento ejercer los derechos otorgados por la Ley Nº19.628 sobre protección de la vida privada y sus
                    modificaciones posteriores. En específico, podrá:
                </p>

                <p>
                    <ul>
                        <li>
                            <span className="fw-bold">Solicitar acceso sobre los datos relativos a su persona, su procedencia y destinatario, </span>
                            el propósito del almacenamiento y la individualización de las personas u organismos a los cuales sus datos son transmitidos regularmente;
                        </li>
                        <li>
                            <span className="fw-bold">Solicitar la modificación o rectificación de sus datos personales </span>
                            cuando ellos sean erróneos, inexactos, equívocos o incompletos;
                        </li>
                        <li>
                            <span className="fw-bold">Solicitar la eliminación o cancelación de sus datos personales </span>
                            cuando su almacenamiento carezca de fundamento legal o se encuentren caducos, salvo que concurra alguna excepción legal.
                        </li>
                        <li>
                            <span className="fw-bold">Solicitar la suspensión temporal de cualquier operación de tratamiento de sus datos personales </span>
                            cuando el usuario haya proporcionado voluntariamente sus datos o ellos se usen para comunicaciones informativas y no desee continuar figurando en el registro respectivo de modo temporal o definitivo, o cuando la exactitud de los datos personales no pueda ser establecida o cuya vigencia sea dudosa y respecto de los cuales no corresponda la cancelación.
                        </li>
                    </ul>
                </p>

                <h4>Sobre la publicidad</h4>
                <p>
                    La información recopilada en el sitio web no se utilizará en publicidad ya que Amática no maneja publicidad de terceros en sus plataformas,
                    la unica posible publicidad será sobre Amática y estará dentro de <a _blank href="amatica.cl">amatica.cl</a>.
                </p>
                <p>
                    No se enviarán ningún tipo de publicidad sobre Amática a los medios de contacto recibidos a menos que
                    el usuario solicite suscribirse a un medio de publicidad mediante newsletter.
                </p>

                <h4>Sobre el mal uso del sitio web</h4>
                <p>
                    Nos reservamos el derecho a tomar acciones legales hasta llegar a tribunales de Justicia que así lo requiere, en el caso de que identifiquemos
                    cualquier mal uso intencionado con o mediante alguna de las plataformas de pertenecientes o dependiente de Amática. La vulneración de la
                    propiedad digital y sus usuarios no es permitida ni si quiera durante análisis de seguridad porque contradice los derechos de los usuarios.
                </p>

            </div>

        </div>
    )
}

export default DeclaracionDePrivacidad;