import React from "react";
import penrose from "../../images/logo/logo-amatica-azul2.png";
import "../../index.css";
import { useLocation, Link } from 'react-router-dom'

function PrincipalFooter({ isDay }) {
    const path = useLocation().pathname;
    let containerHeader = "";
    if (path.includes("casosDeUso"))
        containerHeader = "bg-dark";

    return (
        <div className={containerHeader}>
            <footer className="container pt-4 pt-md-5 py-3 text-white  bg-dark redondito-arriba">
                <div className="row px-lg-5 px-3 col-10 mx-auto">
                    <div className="col-6 col-md text-start">
                        <h5>Menu Principal</h5>
                        <ul className="list-unstyled text-small fw-light">
                            <li className="mb-1">
                                <Link to="/" className="nav-link">Inicio</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/servicios" className="nav-link">Servicios</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/casosDeUso" className="nav-link">Casos de uso</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/contacto" className="nav-link">Contacto</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-6 col-md text-start">
                        <h5>Recursos</h5>
                        <ul className="list-unstyled text-small fw-light">
                            <li className="mb-1">
                                <Link to="/contacto" className="nav-link">Contacto</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/declaracionDePrivacidad" className="nav-link">Política de privacidad</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-6 fw-bold">
                        <div className="col-12 py-3"></div>
                        <div className="mx-auto text-center">
                            <img className="img-fluid pb-2" style={{ height: "50px" }} src={penrose} alt="" />
                            <span className="h2 pb-2"> Amática</span>
                            <div className="lead pb-2">"Donde existen los problemas, florecen las oportunidades"
                                <div>💻 🤖 🌸</div>
                            </div>
                            <small className="d-block my-3 lead fs-6 text-white">&copy; Amática {new Date().getFullYear()}</small>
                        </div>
                    </div>
                    <div className="col-12 py-3"></div>
                </div>
            </footer >
        </div >
    );
}

export default PrincipalFooter;
