import React from "react";
import Body from '../landing-page/PrincipalBody';

function LandingPage({ isDay }) {
    return (
        <div className="">
            <Body isDay={isDay} />
        </div>
    );
}

export default LandingPage;
