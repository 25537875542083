import ClienteHttp from '../ClienteHttp';
const controlador = "Cliente";

// generar un nuevo pase generico
export const GetParamsFormularioContacto = () => {
    return new Promise((resolve, eject) => {
        ClienteHttp.get(`${controlador}/GetParamsFormularioContacto`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log('ERROR GetParamsFormularioContacto: ', eject.error);
                resolve(error.response);
            });
    });
};

export const AddClienteFormularioContacto = cliente => {
    return new Promise((resolve, eject) => {
        ClienteHttp.post(`${controlador}/AddClienteFormularioContacto`, cliente)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log('ERROR AddClienteFormularioContacto: ', eject.error);
                resolve(error.response);
            });
    });
};