import { useState } from "react";
import Toastify from 'toastify-js';

import ChicaSentada from "../../images/login/chica-sentada-login.png";
import LogoNegro from "../../images/logo/logo-amatica-bordes-negro.png";
import LogoBlanco from "../../images/logo/logo-amatica-bordes-blanco.png";

function PrincipalLogin({ isDay }) {
    const [validado, setValidado] = useState(false);
    const [values, setValues] = useState({
        usuario: "",
        password: "",
        recordarCheck: false,
    });

    function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;

        console.log("cambiando");

        const newValues = {
            ...values,
            [name]: value,
        };

        console.log(newValues);


        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    const [recordarContrasena, setRecordarContrasena] = useState(false);
    const onOptionChange = e => {
        setRecordarContrasena(!e.target.value);
        console.log(recordarContrasena);
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        console.log("formulario enviado con esta data");
    }

    const validar = () => {
        let infoForm = {
            usuario: values["usuario"],
            password: values["password"],
            recordarCheck: values["recordarCheck"]
        };

        if (infoForm.usuario.length === 0
            || infoForm.password.length === 0) {

            console.log("validacion invalida");

            Toastify({
                text: "(*) Debes llenar los campos obligatorios del formulario 📄📄",
                className: "info",
                duration: 5000,
                style: {
                    background: "linear-gradient(to right, #ff5099, #ff0f4f)",
                    borderRadius: "0px 0px 15px 15px"
                },
            }).showToast();
            setValidado(false);
            return validado;
        }

        setValidado(true);
        return validado;
    };


    return (
        <div className={isDay === "dia" ? "container col-xxl-8 px-4 py-5" : "container col-xxl-8 px-4 py-5 text-white"}>
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <title>Login de Amática</title>

                <div className="row col-md-6 col-12 mx-auto mt-5">
                    <div className="col-4 mx-auto">
                        <img onContextMenu={(e) => e.preventDefault()} src={isDay === "dia" ? LogoNegro : LogoBlanco} className="img-fluid" alt="" />
                    </div>
                    <h2 className="mt-2">Iniciar Sesión</h2>
                    <div className="col-12 py-3"></div>

                    <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                        <div className="row g-3 tab-content text-start">
                            <div className="col-md-10 col-12 mx-auto">
                                <label htmlFor="usuario" className="form-label campo-requerido">Nombre de usuario</label>
                                <div className="input-group">
                                    <span className={isDay === "dia" ? "input-group-text" : "input-group-text bg-black"} id="inputGroupPrepend">
                                        <i className={isDay === "dia" ? "bi bi-person" : "bi bi-person text-white"}></i>
                                    </span>
                                    <input value={values.usuario} onChange={handleChange} type="text"
                                        className={isDay === "dia" ? "form-control rounded-end" : "form-control rounded-end bg-dark text-white"}
                                        aria-describedby="inputGroupPrepend" name="usuario" required />
                                    <div className="invalid-feedback">
                                        Debes ingresar el nombre de usuario recibido.
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-10 col-12 mx-auto">
                                <label htmlFor="password" className="form-label campo-requerido">Contraseña</label>
                                <div className="input-group  has-validation">
                                    <span className={isDay === "dia" ? "input-group-text" : "input-group-text bg-black"}>
                                        <i className={isDay === "dia" ? "bi bi-key" : "bi bi-key text-white"}></i>
                                    </span>
                                    <input value={values.password} onChange={handleChange} type="password"
                                        className={isDay === "dia" ? "form-control rounded-end" : "form-control rounded-end bg-dark text-white placeholderWhite"}
                                        name="password" required />
                                    <div className="invalid-feedback">
                                        Debes ingresar la clave recibida.
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-10 col-12 mx-auto">
                                <div className="checkbox mb-3">
                                    <label htmlFor="recordarCheck">
                                        <input type="checkbox" name="recordarCheck" value={recordarContrasena} onChange={onOptionChange} /> Recordar Contraseña
                                    </label>
                                </div>
                            </div>

                            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
                                {/*<button type="submit" className="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" data-bs-target="#exampleModal">Enviar</button>*/}
                                <button type="submit" onClick={validar} className={isDay === "dia" ? "btn border border-dark btn-outline-dark btn-lg px-4" : "btn border border-light btn-outline-light btn-lg px-4"}>Ingresar</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-12  col-lg-6 mx-auto">
                    <h1 className="display-6 fw-bold lh-1 mb-3">Login para invitados</h1>
                    <img onContextMenu={(e) => e.preventDefault()} src={ChicaSentada} className="img-fluid" alt="" />

                    <div className="my-4">
                        <p className="lead">Si usted ha recibido las credenciales por un proyecto en proceso, debe ingresar por aquí para rellenar los datos acordados.</p>
                        <p className="lead">Todos los datos recopilados acerca de los usuarios dentro de los formularios personalizados son utilizados únicamente para los sistemas que corresponden con el usuario.</p>
                    </div>
                </div>

            </div>

            <div className="col-12 py-lg-4"></div>
        </div>
    )
}
export default PrincipalLogin;