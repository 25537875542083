import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./landing-page/LandingPage";
import Contacto from "./landing-page/contacto/Contacto";
import Servicios from "./landing-page/servicios/Servicios";
import Muestras from "./landing-page/muestra/Muestras";
import Login from "./landing-page/login/Login";
import DeclaracionDePrivacidad from "./landing-page/DeclaracionPrivacidad";
import NotFound from "./landing-page/NotFound";

function DefaultRouter({ isDay }) {
    return (
        < Routes >
            <Route path="/" element={<Home isDay={isDay} />} />
            <Route path="/contacto" element={<Contacto isDay={isDay} />} />
            <Route path="/servicios" element={<Servicios isDay={isDay} />} />
            <Route path="/casosDeUso" element={<Muestras isDay={isDay} />} />
            <Route path="/login" element={<Login isDay={isDay} />} />
            <Route path="/declaracionDePrivacidad" element={<DeclaracionDePrivacidad isDay={isDay} />} />

            <Route path="*" element={<NotFound isDay={isDay} />} />

        </Routes >
    );
}

export default DefaultRouter;
