import { React, useState } from "react";
import penrose from "../../images/logo/logo-amatica-azul2.png";
//import logoBordesBlancos from "../../images/logo/logo-amatica-bordes-blanco.png";
import Toastify from 'toastify-js';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'

function PrincipalHeader({ setIsDay }) {
    const [modoActual, setModoActual] = useState(window.localStorage.getItem("modo_color") === null ? "dia" : window.localStorage.getItem("modo_color"));
    const path = useLocation().pathname;

    var currentLocation = window.location.href;
    let containerHeader = "";
    if (currentLocation.includes("casosDeUso"))
        containerHeader += "bg-dark";

    function cambiarModoVer() {
        window.localStorage.setItem("modo_color", modoActual === "dia" ? "noche" : "dia");
        setModoActual(window.localStorage.getItem("modo_color"));
        setIsDay(window.localStorage.getItem("modo_color"));

        if (modoActual != "dia") {
            Toastify({
                text: "Modo día, todo claro 👓👓",
                className: "info",
                duration: 5000,
                style: {
                    color: "black",
                    background: "linear-gradient(to right, #f5f775, #ffc53e)",
                    borderRadius: "0px 0px 15px 15px"
                },
            }).showToast();
        } else {
            Toastify({
                text: "Modo noche, vista relajada 🌙🌙",
                className: "info",
                duration: 5000,
                style: {
                    color: "white",
                    background: "linear-gradient(to right, #20a5d9, #1f80d5)",
                    borderRadius: "0px 0px 15px 15px"
                },
            }).showToast();
        }


    }

    return (
        <header className={containerHeader}>
            <nav className="navbar container navbar-expand-lg navbar-dark bg-dark px-3 redondito-abajo">
                <div className="container-fluid my-2">
                    <Link to="/" className="nav-link navbar-brand d-flex align-items-center">
                        <img className="bi me-2" width="40" height="32" src={penrose} alt="Logo" />
                        Amática
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end mt-3 mt-md-0" id="navbarSupportedContent">
                        <ul className="navbar-nav me-3 mb-2 mb-lg-0 text-center">
                            <li className="nav-item">

                                <Link to="/" className={path === "/" ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-house"></i> Inicio
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/servicios" className={path === "/servicios" ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-list-check"></i> Servicios
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/casosDeUso" className={path === "/casosDeUso" ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-code-square"></i> Casos de Uso
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contacto" className={path === "/contacto" ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-envelope"></i> Contacto
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/login" className={path === "/login" ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-person"></i> Login
                                </Link>
                            </li>

                            <li className="nav-item ms-md-4 mt-2 d-flex justify-content-center text-white">
                                <i className={modoActual === "dia" ? "bi bi-brightness-high-fill me-2 text-warning" : "bi bi-brightness-high-fill me-2"}></i>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" onChange={() => cambiarModoVer()} checked={modoActual === "dia" ? false : true} id="flexSwitchCheckChecked" />
                                </div>
                                <i className={modoActual === "noche" ? "bi bi-moon-fill text-primary" : "bi bi-moon-fill"}></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default PrincipalHeader;
