import { React } from "react";
import notFound from "../images/not-found.png";
import { Link } from "react-router-dom";

function PincipalNotFound({ isDay }) {
    return (
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-12 col-md-8 mx-auto text-center">
                    <div className={isDay === "dia" ? "" : "text-light"}>
                        <div className="display-1 fw-bold">404</div>
                        <div className="fs-2">Página no encontrada</div>
                    </div>

                    <img onContextMenu={(e) => e.preventDefault()} src={notFound}
                        className="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />
                </div>

                <div className={isDay === "dia" ? "col-12" : "col-12 text-light"}>
                    <h1 className="fw-bold lh-1 mb-3">Ups... Parece que esta ruta no existe actualmente</h1>
                    <p className="lead">Pero no te preocupes, déjame llevarte al inicio del sitio</p>
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-center my-4">
                    <button type="button" className="btn btn-danger btn-lg px-4 me-md-2">
                        <Link to="/" class="nav-link">
                            Volver al inicio
                        </Link>
                    </button>
                </div>
            </div>
        </div >
    );
}

export default PincipalNotFound;
