import React, { useState, useEffect } from 'react';
import { Bar, Line, PolarArea, Doughnut, Radar, Pie } from 'react-chartjs-2';
import { CDBContainer } from 'cdbreact';
import Chart from 'chart.js/auto'
import MujerEstresada from "../../images/muestra/mujer-estresada.png";
import MujerAdmin from "../../images/muestra/mujer-administrando.png";

const ChartApp = ({ isDay }) => {
    const [data, setData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(71, 225, 167, 0.5)',
                pointHoverBorderColor: 'rgb(71, 225, 167)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [65, 59, 80, 81, 56, 55, 40],
            },
        ],
    });

    useEffect(() => {
        setInterval(function () {
            var oldDataSet = data.datasets[0];
            var newData = [];

            for (var x = 0; x < data.labels.length; x++) {
                newData.push(Math.floor(Math.random() * 100));
            }

            var newDataSet = {
                ...oldDataSet,
            };

            newDataSet.data = newData;

            var newState = {
                ...data,
                datasets: [newDataSet],
            };

            setData(newState);
        }, 3000);
    }, []);

    const [data2] = useState({
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40],
                pointBorderWidth: 3,
                pointHoverRadius: 15,
            },
            {
                label: 'My Second dataset',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 100],
                pointBorderWidth: 3,
                pointHoverRadius: 15,
            },
        ],
        options: {
            legend: {
                labels: {
                    fontColor: "blue",
                    fontSize: 18
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: "green",
                        fontSize: 18,
                        stepSize: 1,
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: "purple",
                        fontSize: 14,
                        stepSize: 1,
                        beginAtZero: true
                    }
                }]
            }
        }
    });

    const [data3] = useState({
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40],
            },
            {
                label: 'My Second dataset',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 100],
            },
        ],
    });

    const [data4, setData4] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(71, 225, 167, 0.5)',
                pointHoverBorderColor: 'rgb(71, 225, 167)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [65, 59, 80, 81, 56, 55, 40],
            },
        ],
    });

    useEffect(() => {
        setInterval(function () {
            var oldDataSet = data4.datasets[0];
            var newData = [];

            for (var x = 0; x < data4.labels.length; x++) {
                newData.push(Math.floor(Math.random() * 100));
            }

            var newDataSet = {
                ...oldDataSet,
            };

            newDataSet.data = newData;

            var newState = {
                ...data,
                datasets: [newDataSet],
            };

            setData4(newState);
        }, 2000);
    }, []);

    const [data5] = useState({
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40],
            },
            {
                label: 'My Second dataset',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 100],
            },
        ],
    });

    const [data6] = useState({
        labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40],
            },
            {
                label: 'My Second dataset',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 100],
            },
        ],
    });

    return (
        <div className='row mx-auto'>

            <div className='col-lg-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico Lineal</h3>
                    <Line data={data2} options={{ responsive: true }} />
                </CDBContainer>
            </div>

            <div className='col-lg-3 col-sm-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico Polar</h3>
                    <PolarArea data={data3} options={{ responsive: true }} />
                </CDBContainer>
            </div>

            <div className='col-lg-3 col-sm-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico Rosquilla Dinámica</h3>
                    <Doughnut data={data4} options={{ responsive: true }} />
                </CDBContainer>
            </div>

            <div className="col-12 p-lg-4 p-2"></div>

            {/** MUJER ESTADISTICAS **/}
            <div className="">
                <div className="row col-lg-10 col-11 mx-auto">
                    <div className="col-lg-4 col-12">
                        <img onContextMenu={(e) => e.preventDefault()}
                            src={MujerEstresada} className="img-fluid" alt="" loading="lazy" />
                    </div>

                    <div className="col-lg-4 col-12">
                        <h1 className="display-5 fw-bold lh-1 mb-3" style={{ color: "rgba(71, 225, 167)" }}>Moderniza tu gestión de personal con Amatica</h1>
                        <h1 className="display-6 fw-bold lh-1 mb-3" style={{ color: "rgba(194, 116, 161)" }}> Adiós a los registros en papel, hola a la eficiencia digital.</h1>
                        <p className="lead">Simplifica la administración, accede instantáneamente a datos seguros, y genera informes detallados con solo unos clics. Cumple con facilidad y potencia tu gestión con Amatica.</p>
                    </div>

                    <div className="col-lg-4 col-12">
                        <img onContextMenu={(e) => e.preventDefault()}
                            src={MujerAdmin} className="img-fluid" alt="" loading="lazy" />
                    </div>
                </div>
            </div>

            <div className="col-12 p-lg-4 p-2"></div>

            <div className='col-lg-3 col-sm-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico Radar</h3>
                    <Radar data={data5} options={{ responsive: true }} />
                </CDBContainer>
            </div>

            <div className='col-lg-3 col-sm-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico Torta</h3>
                    <Pie data={data6} options={{ responsive: true }} />
                </CDBContainer>
            </div>

            <div className='col-lg-6 col-12'>
                <CDBContainer>
                    <h3 className="">Gráfico de Barras Dinámico</h3>
                    <Bar data={data} options={{ responsive: true }} />
                </CDBContainer>
            </div>

        </div>

    );
};

export default ChartApp;