import './App.css';
import DefaultRouter from './router';
import Header from './landing-page/header/Header';
import Footer from './landing-page/footer/Footer';
import MensajeriaGeneral from './landing-page/MensajeriaGeneral';
import { useEffect, useState } from "react";

function App() {
  const [isDay, setIsDay] = useState(window.localStorage.getItem("modo_color") == null
    ? "dia"
    : window.localStorage.getItem("modo_color"));
  const [tema, setTema] = useState("App fondo-principal");

  useEffect(() => {
    setTema(isDay === "dia" ? "App fondo-principal" : "App fondo-noche");
  }, [isDay]);

  return (
    <div className={tema}>
      <Header setIsDay={setIsDay} />
      <DefaultRouter isDay={isDay} />
      <Footer isDay={isDay} />
      <MensajeriaGeneral />
    </div>
  );
}

export default App;
